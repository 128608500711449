import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import Calendar from 'v-calendar/lib/components/calendar.umd';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';

Vue.config.productionTip = false;

import api from '@p/api';
Vue.use(api, { store });

import dialog from '@p/dialog';
Vue.use(dialog, {
    vuetify,
});
Vue.component('calendar', Calendar);
Vue.component('date-picker', DatePicker);

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app');
