<template>
    <div>
        <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="query"
                append-icon="mdi-magnify"
                label="Поиск"
                single-line
                hide-details
                @input="search"
            ></v-text-field>
        </v-card-title>
        <v-data-table
        :headers="headers"
        :items="list"
        :items-per-page="-1"
        class="elevation-1"
        :loading="loading"
        :options.sync="options"
        page.sync="1"
        loading-text="Загрузка..."
        :server-items-length="totalCount"
        :footer-props="{
        itemsPerPageOptions: [10, 50, 100, 150],
        disableItemsPerPage: false,
        'items-per-page-text':'Элементов на странице:',
        'items-per-page-all-text':'Все'
        }"
        >
            <template v-slot:top>
                <v-dialog
                    v-model="dialogEdit"
                    max-width="500px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                        color="primary"
                        dark
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                        >
                        Добавить баннер
                        </v-btn>
                    </template>

                    <v-card>
                        <v-card-title>
                            <span class="text-h5">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col
                                        cols="12"
                                        :sm="12"
                                        :md="12"
                                    >
                                        <v-text-field
                                            v-model="editedItem.name"
                                            label="Название баннера"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        :sm="12"
                                        :md="12"
                                    >
                                        <v-file-input label="Файл баннера"
                                            accept="image/jpeg, image/png"
                                            v-model="editedItem.newImage"></v-file-input>
                                        <div class="d-flex flex-column justify-space-between align-center"
                                            v-if="editedItem.id > 0">
                                            <v-img
                                                :src="$store.state.api + editedItem.image"
                                                max-height="200" :contain="true" />
                                        </div>
                                    </v-col>
                                    <v-col
                                        v-if="editedItem.type === 6"
                                        cols="12"
                                        :sm="12"
                                        :md="12"
                                    >
                                        <v-file-input label="Файл баннера для мобильного"
                                            accept="image/jpeg, image/png"
                                            v-model="editedItem.newImageMobile"></v-file-input>
                                        <div class="d-flex flex-column justify-space-between align-center"
                                            v-if="editedItem.id > 0 && editedItem.imageMobile">
                                            <v-img
                                                :src="$store.state.api + editedItem.imageMobile"
                                                max-height="200" :contain="true" />
                                        </div>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-select label="Тип баннера" v-model="editedItem.type" :items="types" :menu-props="{ maxHeight: '400' }" persistent-hint item-text="name" item-value="id"></v-select>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        :sm="12"
                                        :md="12"
                                    >
                                        <v-text-field
                                        v-model="editedItem.link"
                                        label="Ссылка баннера"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        :sm="12"
                                        :md="12"
                                    >
                                        <v-checkbox label="Безлимитное размещение?" v-model="editedItem.unlimited" @change="resetDates()"></v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row v-if="!editedItem.unlimited">
                                    <v-col
                                        cols="12"
                                        :sm="6"
                                        :md="6"
                                    >
                                        <v-dialog
                                            ref="dialog1"
                                            v-model="dateDialogStart"
                                            :return-value.sync="editedItem.start"
                                            persistent
                                            width="290px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="editedItem.start"
                                                label="Начало размещения"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="editedItem.start"
                                                scrollable
                                                locale="ru-RU"
                                            >
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="dateDialogStart = false"
                                            >
                                                Отмена
                                            </v-btn>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="$refs.dialog1.save(editedItem.start)"
                                            >
                                                Ок
                                            </v-btn>
                                            </v-date-picker>
                                        </v-dialog>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        :sm="6"
                                        :md="6"
                                    >
                                        <v-dialog
                                            ref="dialog2"
                                            v-model="dateDialogEnd"
                                            :return-value.sync="editedItem.end"
                                            persistent
                                            width="290px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="editedItem.end"
                                                label="Окончание размещения"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="editedItem.end"
                                                scrollable
                                                locale="ru-RU"
                                            >
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="dateDialogEnd = false"
                                            >
                                                Отмена
                                            </v-btn>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="$refs.dialog2.save(editedItem.end)"
                                            >
                                                Ок
                                            </v-btn>
                                            </v-date-picker>
                                        </v-dialog>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                        cols="12"
                                        :sm="12"
                                        :md="12"
                                    >
                                        <v-checkbox label="Скрыть баннер?" v-model="editedItem.hide"></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="closeEdit"
                            >
                                Отмена
                            </v-btn>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="save"
                            >
                                Сохранить
                            </v-btn>
                        </v-card-actions>
                    </v-card>

                    <file-uploader ref="uploader" />
                </v-dialog>

                <v-dialog
                v-model="dialogRemove"
                max-width="500px"
                >
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">Удаление баннера</span>
                        </v-card-title>

                        <v-card-text>Вы действительно хотите удалить указанный баннер?</v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="closeRemove"
                            >
                                Отмена
                            </v-btn>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="removeItem(itemToRemove)"
                            >
                                Удалить
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>

            <template v-slot:item.actions="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                    title="Изменить баннер"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    small
                    class="mr-2"
                    @click="openRemoveDialog(item)"
                    title="Удалить баннер"
                >
                    mdi-delete
                </v-icon>
            </template>
            <template v-slot:item.hide="{ item }"><v-icon small v-if="item.hide">mdi-check-bold</v-icon></template>
            <template v-slot:item.unlimited="{ item }"><v-icon small v-if="item.unlimited">mdi-check-bold</v-icon></template>
        </v-data-table>
    </div>
</template>

<script>
// todo: запрогать поиск
import FileUploader from "@c/FileUploader.vue";

export default {
    name: "Banners",
    components: {
        FileUploader
    },
    data() {
        return {
            query: '',
            list: [],
            types: [],
            headers: [
                {
                    value: "id",
                    text: "ID",
                    sortable: false,
                },
                {
                    value: "name",
                    text: "Имя",
                    sortable: false,
                },
                {
                    value: "image",
                    text: "Файл",
                    sortable: false,
                },
                {
                    value: "type.name",
                    text: "Тип",
                    sortable: false,
                },
                {
                    value: "created",
                    text: "Создан",
                    sortable: false,
                },
                {
                    value: "unlimited",
                    text: "Безлимит",
                    sortable: false,
                },
                {
                    value: "start",
                    text: "Начало",
                    sortable: false,
                },
                {
                    value: "end",
                    text: "Окончание",
                    sortable: false,
                },
                {
                    value: "hide",
                    text: "Скрыт?",
                    sortable: false,
                },
                {
                    value: 'actions',
                    text: 'Действия',
                    sortable: false,
                    align: 'end',
                },
            ],
            loading: false,
            dialogEdit: false,
            editedIndex: -1,
            totalCount: -1,
            hasNext: false,
            dateDialogStart: false,
            dateDialogEnd: false,
            page: 1,
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: ['id']
            },
            defaultItem: {},
            editedItem: {
                id: 0,
                name: '',
                image: '',
                imageMobile: '',
                newImage: {},
                newImageMobile: {},
                unlimited: false,
                start: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                end: (new Date((Date.now() + 1*24*60*60*1000) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                hide: false,
                type: 0,
                link: ''
            },
            dialogRemove: false,
            itemToRemove: -1
        };
    },
    watch: {
        options(pagination) {
            this.page = pagination.page;
            this.getList(pagination.page);
        },
        dialogEdit (val) {
            val || this.closeEdit();
        },
    },
    mounted() {
        this.getTypes();
    },
    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'Новый баннер' : 'Редактирование баннера #' + this.editedItem.id;
        },
    },
    methods: {
        async search () {
            await this.getList();
        },
        resetDates() {
            if (!this.editedItem.unlimited) {
                this.editedItem.start = this.defaultItem.start;
                this.editedItem.end = this.defaultItem.end;
            }
        },
        async getList(рg = 1) {
            this.loading = true;

            try {
                const { list, hasNext, totalCount } = await this.$api(`/obyavleniya?${new URLSearchParams({
                    pg: рg,
                    on: this.options.itemsPerPage,
                    query: this.query,
                    notHidden: 0
                })}`);
                this.list = list;
                this.hasNext = hasNext;
                this.totalCount = totalCount;
            } catch (e) {
                this.$error(e.message);
            }

            this.loading = false;
        },
        async getTypes() {
            try {
                const { list, sizes } = await this.$api("/admin/obyavleniya_types");
            
                list.forEach(function(item, i) {
                    list[i]['name'] = item.name + ' (' + sizes[list[i]['id']] + ')';
                });

                this.types = list;
            } catch (e) {
                this.$error(e.message);
            }
        },
        editItem (item) {
            this.editedIndex = this.list.indexOf(item);
            this.defaultItem = Object.assign({}, this.editedItem);
            this.editedItem = Object.assign({}, item, {
                type: item.type.id,
                start: item.start,
                end: item.end
            });
            this.dialogEdit = true;
        },
        async removeItem (item) {
            try {
                let r = await this.$api(`/admin/obyavleniya/${item.id}`, {
                    method: "DELETE"
                });
                if (!r.error) {
                    this.getList();
                    this.closeRemove();
                }
            } catch (e) {
                this.$error(e.message);
            }
        },
        closeRemove () {
            this.dialogRemove = false;
            this.$nextTick(() => {
                this.itemToRemove = -1;
            });
        },
        closeEdit () {
            this.dialogEdit = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        async uploadImage() {
            let i = -1;

            if (this.editedItem.newImage || this.editedItem.newImageMobile) {
                const needUpload = [];
                if (this.editedItem.newImage instanceof File) {
                    this.editedItem.newImage.id = i--;
                    this.editedItem.newImage.mode = 'PC';
                    this.editedItem.newImage.typeId = this.editedItem.type;
                    needUpload.push(this.editedItem.newImage);
                } 
                
                if (this.editedItem.type == 6 && this.editedItem.newImageMobile instanceof File) {
                    this.editedItem.newImageMobile = Object.assign(
                        this.editedItem.newImageMobile,
                        {
                            id: i--,
                            mode: 'Mobile',
                            typeId: this.editedItem.type
                        }
                    );
                    needUpload.push(this.editedItem.newImageMobile);
                }

                delete this.editedItem.newImage;
                delete this.editedItem.newImageMobile;
                const files = await this.$refs.uploader.upload(needUpload);

                const banners = [];
                for (let f of files) {
                    const banner = {
                        tmp: f.tmp,
                        name: f.name,
                        mode: f.mode,
                        typeId: f.typeId,
                        size: Math.ceil(f.size / 1024 / 1024),
                        type: f.type.split('/')[1]
                    };

                    banners.push(banner);
                }

                return banners;
            }

            return [];
        },
        async save () {
            const imagesFiles = await this.uploadImage();
            let image, imageMobile = {};

            image = imagesFiles.find(item => item.mode === 'PC') ?? {};
            imageMobile = imagesFiles.find(item => item.mode === 'Mobile') ?? {};

            if (this.editedIndex > -1) {
                try {
                    let r = await this.$api("/admin/obyavleniya/" + this.editedItem.id, {
                        method: "PUT",
                        body: {
                            name: this.editedItem.name,
                            image: image.tmp ?? '',
                            imageMobile: imageMobile.tmp ?? '',
                            type_id: this.editedItem.type,
                            unlimited: this.editedItem.unlimited,
                            start: this.editedItem.start,
                            end: this.editedItem.end,
                            hide: this.editedItem.hide,
                            link: this.editedItem.link
                        }
                    });
                    if (!r.error) {
                        this.getList(this.page);
                        this.closeEdit();
                    }
                } catch (e) {
                    this.$error(e.message);
                }
            } else {
                try {
                    let r = await this.$api("/admin/obyavleniya", {
                        method: "POST",
                        body: {
                            name: this.editedItem.name,
                            image: image.tmp ?? '',
                            imageMobile: imageMobile.tmp ?? '',
                            type_id: this.editedItem.type,
                            unlimited: this.editedItem.unlimited,
                            start: this.editedItem.start,
                            end: this.editedItem.end,
                            hide: this.editedItem.hide,
                            link: this.editedItem.link
                        },
                    });
                    if (!r.error) {
                        this.getList();
                        this.closeEdit();
                    }
                } catch (e) {
                    this.$error(e.message);
                }
            }
        },
        openRemoveDialog (item) {
            this.itemToRemove = item;
            this.dialogRemove = true;
        },
    }
};
</script>
