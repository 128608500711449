import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@v/Home.vue';
import Login from '@v/Login.vue';

Vue.use(VueRouter);

/**
 * @type {import('vue-router').RouteConfig[]}
 */
const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: 'Домашняя страница',
        },
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            title: 'Вход',
        },
        component: Login,
    },
    {
        path: '/users',
        name: 'Users',
        meta: {
            title: 'Пользователи',
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@v/Users'),
    },
    {
        path: '/news',
        name: 'News',
        meta: {
            title: 'Новости',
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@v/News'),
    },
    {
        path: '/comments',
        name: 'Comments',
        meta: {
            title: 'Комментарии к новостям',
        },
        component: () => import('@v/Comments'),
    },
    {
        path: '/tags',
        name: 'Tags',
        meta: {
            title: 'Категории новостей',
        },
        component: () => import('@v/Tags'),
    },
    {
        path: '/banners',
        name: 'Banners',
        meta: {
            title: 'Баннеры',
        },
        component: () => import('@v/Banners'),
    },
    {
        path: '/ads',
        name: 'Ads',
        meta: {
            title: 'Объявления',
        },
        component: () => import('@v/Ads'),
    },
    {
        path: '/categories',
        name: 'Categories',
        meta: {
            title: 'Категории объявлений',
        },
        component: () => import('@v/Categories'),
    },
    {
        path: '/orders',
        name: 'Orders',
        meta: {
            title: 'Заказы объявлений',
        },
        component: () => import('@v/Orders'),
    },
    {
        path: '/organization-card-categories',
        name: 'OrganizationCardCategories',
        meta: {
            title: 'Категории карточки организации',
        },
        component: () => import('@v/OrganizationCardCategories'),
    },
    {
        path: '/organization-cards',
        name: 'OrganizationCards',
        meta: {
            title: 'Карточки организаций',
        },
        component: () => import('@v/OrganizationCards'),
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

import seo from './seo';
router.beforeEach(seo);

import auth from './auth';
router.beforeEach(auth);

export default router;
