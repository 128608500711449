<template>
    <div>
        <v-dialog
            v-model="dialogEdit"
            :retain-focus="false"
            max-width="900px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">Редактирование блока</span>
                </v-card-title>
                <v-card-text>
                    <template v-if="editedItem.type == 1 || editedItem.type == 2">
                        <v-text-field label="Текст" v-model="editedItem.data.value.text"></v-text-field>
                        <v-select
                            v-model="editedItem.data.value.align"
                            :items="alignValues"
                            label="Выравнивание"
                            item-text="title"
                            item-value="id"
                        ></v-select>
                    </template>
                    <template v-if="editedItem.type == 3">
                        <editor
                            :editor="editor"
                            v-model="editedItem.data.value"
                            :config="editorConfig"
                        />
                    </template>
                    <template v-if="editedItem.type == 4">
                        <v-text-field label="Текст" v-model="editedItem.data.value.text"></v-text-field>
                        <v-text-field label="Ссылка" v-model="editedItem.data.value.link"></v-text-field>
                        <v-select
                            v-model="editedItem.data.value.align"
                            :items="alignValues"
                            label="Выравнивание"
                            item-text="title"
                            item-value="id"
                        ></v-select>
                    </template>
                    <template v-if="editedItem.type == 5">
                        <v-file-input label="Загрузка изображения" v-model="editedItem.data.value.newValue"></v-file-input>
                        <v-text-field label="Подпись" v-model="editedItem.data.value.text"></v-text-field>
                        <div v-if="editedItem.id > 0">
                            <v-img
                                max-height="150"
                                max-width="250"
                                :src="API_URL + editedItem.data.image"
                            ></v-img>
                            <label>{{ editedItem.data.value.file.name }}</label>
                        </div>
                    </template>
                    <template v-if="editedItem.type == 6">
                        <v-file-input label="Загрузка видео" v-model="editedItem.data.value.newValue"></v-file-input>
                        <div v-if="editedItem.id > 0">
                            <video
                                autoplay
                                :src="API_URL + editedItem.data.video"
                            ></video>
                            <label>{{ editedItem.data.value.name }}</label>
                        </div>
                    </template>
                    <template v-if="editedItem.type == 7 || editedItem.type == 8">
                        <v-file-input label="Загрузка файлов" multiple v-model="editedItem.data.value.list"></v-file-input>
                    </template>
                    <template v-if="editedItem.type == 9">
                        <div class="d-flex flex-column">
                            <v-text-field label="Тема опроса" v-model="editedItem.data.value.title"></v-text-field>
                            <template>
                                <v-btn color="primary" class="mb-2 mr-auto" @click="addItemPoolAnswer()">
                                    Добавить блок ответа
                                </v-btn>
                            </template>
                            <label>Всего голосов: {{ editedItem.data.value.totalVote }}</label>
                            <label>Ответы</label>
                            <draggable
                                :list="pollAnswersList"
                            >
                                <v-card class="mt-4 pa-3 d-flex flex-row align-center" v-for="ans in pollAnswersList" :key="ans.id">
                                    <span class="d-flex w-100">
                                        <v-text-field :label="'Голосов: ' + ans.vote" v-model="ans.answer"></v-text-field>
                                    </span>
                                    <v-btn-toggle v-if="editedItem.data.value.answers.length > 1" class="ml-auto pa-3" dense>
                                        <v-btn color="error" @click="openRemoveDialogPullAnswer(ans.id)">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </v-btn-toggle>
                                </v-card>
                            </draggable>
                        </div>
                    </template>
                    <template v-if="editedItem.type == 10">
                        <div v-html="editedItem.data.value"></div>
                        <v-textarea v-model="editedItem.data.value" name="html" label="HTML код" hint="Вставьте сюда HTML код"></v-textarea>
                    </template>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="closeEdit"
                    >
                        Отмена
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="saveItem"
                    >
                        Сохранить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
        v-model="dialogRemove"
        max-width="500px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">Удаление блока</span>
                </v-card-title>

                <v-card-text>Вы действительно хотите удалить указанный блок?</v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="closeRemove"
                    >
                        Отмена
                    </v-btn>
                    <v-btn
                        v-if="!dialogRemovePollAnswer"
                        color="blue darken-1"
                        text
                        @click="removeItem(itemToRemove)"
                    >
                        Удалить
                    </v-btn>
                    <v-btn
                        v-if="dialogRemovePollAnswer"
                        color="blue darken-1"
                        text
                        @click="removeItemPollAnswer(itemToRemovePollAnswer)"
                    >
                        Удалить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-menu bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" class="mb-2" v-bind="attrs" v-on="on">
                    Добавить блок
                </v-btn>
            </template>
            <v-list dense>
                <v-list-item v-for="menuItem in types" :key="menuItem.id" @click="addItem(menuItem.id)">
                    <v-list-item-title>
                        <v-icon class="mr-2">{{ menuItem.icon }}</v-icon> {{ menuItem.title }}
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <draggable
            :list="list"
        >
            <v-card class="mt-4 pa-3 d-flex align-center" v-for="item in list" :key="item.id">
                <v-icon class="mr-2">{{ getTypeById(item.type).icon }}</v-icon>
                <span class="mr-3">
                    <template v-if="item.type == 1 || item.type == 2">
                        {{ item.data.value.text }}
                    </template>
                    <div class="block-text" v-if="item.type == 3" v-html="item.data.value"></div>
                    <a v-if="item.type == 4" :href="item.data.value.link" target="_blank">{{ item.data.value.text }}</a>
                    <template v-if="item.type == 5">{{ item.data.value.file.name }}</template>
                    <template v-if="item.type == 6">{{ item.data.value.name }}</template>
                    <template v-if="item.type == 7">Галерея</template>
                    <template v-if="item.type == 8">Документы</template>
                    <template v-if="item.type == 9">Опрос. {{ item.data.value.title }}</template>
                    <template v-if="item.type == 10">HTML</template>
                </span>
                <v-btn-toggle class="ml-auto" dense>
                    <v-btn @click="editItem(item.id)">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>

                    <v-btn color="error" @click="openRemoveDialog(item.id)">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </v-btn-toggle>
            </v-card>
        </draggable>
        <file-uploader ref="uploader" />
    </div>
</template>

<script>
import Draggable from "vuedraggable";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import FileUploader from "@c/FileUploader.vue";
export default {
    name: "ContentEditor",
    components: {
        Draggable,
        Editor: CKEditor.component,
        FileUploader
    },
    props: {
        list: {
            type: Array,
            required: false,
        },
    },
    data() {
        return {
            API_URL: '',
            dialogEdit: false,
            editedItem: {
                id: -1,
                type: 0,
                data: ''
            },
            defaultItem: {},
            newItemId: 0,
            editor: ClassicEditor,
            editorConfig: {
                language: "ru",
                baseFloatIndex: 9001,
                toolbar: [
                    "heading",
                    "bold",
                    "italic",
                    "blockQuote",
                    "link",
                    "numberedList",
                    "bulletedList",
                    "insertTable",
                ],
            },
            dialogRemove: false,
            dialogRemovePollAnswer: false,
            itemToRemove: -1,
            itemToRemovePollAnswer: -1,
            answersListProvider: []
        };
    },
    computed: {
        types: () => [
            {
                id: 1,
                icon: "mdi-format-header-1",
                title: "Заголовок",
            },
            {
                id: 2,
                icon: "mdi-format-header-2",
                title: "Подзаголовок",
            },
            {
                id: 3,
                icon: "mdi-format-letter-case",
                title: "Текст",
            },
            {
                id: 4,
                icon: "mdi-link",
                title: "Ссылка",
            },
            {
                id: 5,
                icon: "mdi-image",
                title: "Изображение",
            },
            {
                id: 6,
                icon: "mdi-video",
                title: "Видео",
            },
            {
                id: 7,
                icon: "mdi-image-multiple",
                title: "Галерея",
            },
            {
                id: 8,
                icon: "mdi-paperclip",
                title: "Документы",
            },
            {
                id: 9,
                icon: "mdi-poll",
                title: "Опрос",
            },
            {
                id: 10,
                icon: "mdi-xml",
                title: "HTML",
            },
        ],
        alignValues: () => [
            {
                id: 0,
                title: "Не задано",
            },
            {
                id: 1,
                title: "Левый край",
            },
            {
                id: 2,
                title: "Центр",
            },
            {
                id: 3,
                title: "Правый край",
            },
        ],
        pollAnswersList() {
            return this.answersListProvider;
        } 
    },
    watch: {
        dialogEdit (val) {
            val || this.closeEdit();
        },
        dialogRemove (val) {
            val || this.closeRemove();
        }
    },
    mounted() {
        this.API_URL = process.env.VUE_APP_API;
    },
    methods: {
        editItem (id) {
            for (let i = 0; i < this.list.length; i++) {
                if (this.list[i].id == id) {
                    this.defaultItem = this.editedItem;
                    this.editedItem = Object.assign({}, this.list[i]);
                    this.dialogEdit = true;

                    if (this.list[i].type == 9) {
                        this.answersListProvider = this.editedItem.data.value.answers;
                        this.dialogRemovePollAnswer = true;
                    }
                    break;
                }
            }
        },
        closeEdit () {
            this.dialogEdit = false;
            this.dialogRemovePollAnswer = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        async removeItem(id) {
            for (let i = 0; i < this.list.length; i++) {
                if (this.list[i].id == id) {
                    this.list.splice(i, 1);
                    break;
                }
            }
            this.closeRemove();
        },
        async addItemPoolAnswer() {
            let answers = this.editedItem.data.value.answers;
            let id = answers.length > 0 ? answers[answers.length-1].id : 0;
            
            this.editedItem.data.value.answers.push({
                id: ++id,
                answer: '',
                vote: 0
            });
            this.answersListProvider = this.editedItem.data.value.answers;
        },
        async removeItemPollAnswer(id) {
            for (const [index, item] of this.editedItem.data.value.answers.entries()) {
                if (item.id == id) {
                    this.editedItem.data.value.answers.splice(index, 1);
                    break;
                }
            }
            this.answersListProvider = this.editedItem.data.value.answers;
            this.closeRemove();
        },
        addItem(type) {
            this.editedItem.id = 0;
            this.editedItem.type = type;
            switch (type) {
                case 1:
                case 2:
                    this.editedItem.data = {
                        value: {
                            text: '',
                            align: 0
                        }
                    };
                    break;
                case 3:
                case 10:
                    this.editedItem.data = {
                        value: "",
                    };
                    break;
                case 4:
                    this.editedItem.data = {
                        value: {
                            link: "",
                            text: "",
                            align: 0,
                        }
                    };
                    break;
                case 5:
                    this.editedItem.data = {
                        value: {
                            image: [],
                            text: '',
                            newValue: []
                        }
                    };
                    break;
                case 6:
                    this.editedItem.data = {
                        value: {
                            video: [],
                            newValue: []
                        }
                    };
                    break;
                case 7:
                case 8:
                    this.editedItem.data = {
                        value: {
                            list: []
                        }
                    };
                    break;
                case 9:
                    this.answersListProvider = [];
                    this.editedItem.data = Object.assign({}, { value: {
                        id: 0,
                        title: '',
                        answers: [],
                        totalVote: 0
                    } });
                    this.dialogRemovePollAnswer = true;
                    break;
            }
            this.editedItem.id = 0;
            this.editedItem.type = type;
            this.dialogEdit = true;
        },
        async saveItem() {
            if (this.editedItem.id == 0) {
                let itemData = null;

                if (this.editedItem.type == 5 || this.editedItem.type == 6) {
                    itemData = {
                        file: this.editedItem.data.value.newValue
                    };

                    if (this.editedItem.type == 5 && this.editedItem.data.value.text) {
                        itemData = Object.assign({}, itemData, { text: this.editedItem.data.value.text });
                    }
                    
                } else {
                    itemData = this.editedItem.data.value;
                }
                if (this.editedItem.type == 9) {
                    this.editedItem.data.value.id = this.randomInteger(1, 9999);
                }
                
                this.list.push({
                    id: --this.newItemId,
                    type: this.editedItem.type,
                    data: { value: itemData }
                });
            } else {
                // если опрос. проверяем, чтобы не было пустых ответов
                if (this.editedItem.type == 9) {
                    let answers = this.editedItem.data.value.answers;
                    this.editedItem.data.value.answers = answers.filter((item => item.answer != ''));
                    if (!this.editedItem.data.value.id) {
                        this.editedItem.data.value.id = this.randomInteger(1, 99999);
                    }
                }
                
                for (let l of this.list) {
                    if (l.id == this.editedItem.id) {
                        l.id = --this.newItemId;
                        l.data = this.editedItem.data;
                        break;
                    }
                }
            }
            this.dialogEdit = false;
        },
        randomInteger (min, max) {
            let rand = min - 0.5 + Math.random() * (max - min + 1);
            return Math.round(rand);
        },
        async uploadFiles() {
            if (this.editedItem.content) {
                const copy = this.editedItem.content.map((l) => {
                    let c = Object.assign(
                        { ...l },
                        {
                            data: { ...l.data },
                        }
                    );
                    if (l.type == 7 || l.type == 8) {
                        c.data.list = c.data.list.map((l) => ({ ...l }));
                    }
                    return c;
                });
                const needUpload = [];
                for (let c of copy) {
                    if ((c.type == 5 || c.type == 6) && c.data.file) {
                        c.data.file.id = c.id;
                        needUpload.push(c.data.file);
                        delete c.data.file;
                    }
                    if (c.type == 7 || c.type == 8) {
                        for (let l of c.data.list) {
                            if (l.file) {
                                l.file.id = l.id;
                                needUpload.push(l.file);
                                delete l.file;
                            }
                        }
                    }
                }
                if (needUpload.length) {
                    const files = await this.$refs.uploader.upload(needUpload);
                    for (let c of copy) {
                        switch (c.type) {
                            case 5:
                            case 6:
                                for (let f of files) {
                                    if (f.id == c.id) {
                                        c.data = {
                                            tmp: f.tmp,
                                            name: f.name,
                                            size: Math.ceil(f.size / 1024 / 1024),
                                            type: f.type.split('/')[1]
                                        };
                                    }
                                }
                                break;
                            case 7:
                            case 8:
                                for (let l of c.data.list) {
                                    for (let f of files) {
                                        if (f.id == l.id) {
                                            l.tmp = f.tmp;
                                            l.name = f.name;
                                            l.size = Math.ceil(f.size / 1024 / 1024);
                                            l.type = f.type.split('/')[1];
                                        }
                                    }
                                }
                                break;
                        }
                    }
                }
                for (let c of copy) {
                    if (c.type == 7 || c.type == 8) {
                        c.data.list = c.data.list.map((l) => {
                            delete l.id;
                            return l;
                        });
                    }
                }
                return copy;
            }
            return false;
        },
        closeRemove () {
            this.dialogRemove = false;
            this.$nextTick(() => {
                this.itemToRemove = -1;
                this.itemToRemovePollAnswer = -1;
            });
        },
        getTypeById(id) {
            for (let t of this.types) {
                if (t.id == id) {
                    return t;
                }
            }
            return {
                id: 0,
                title: "",
                icon: "",
            };
        },
        openRemoveDialogPullAnswer(item) {
            this.itemToRemovePollAnswer = item;
            this.dialogRemove = true;
        },
        openRemoveDialog (item) {
            this.itemToRemove = item;
            this.dialogRemove = true;
        },
    }
};
</script>

<style lang="scss">
    .w-100 {
        width: 100%;
    }

    .block-text {
        max-width: 100% !important; 
        display: block !important;

        & p {
            max-width: 100% !important; 
            display: block !important;

            & img {
                max-width: 100% !important; 
                display: block !important;
            }
        }
    }
</style>