<template>
    <div>
        <v-row class="d-flex justify-end">
            <v-col cols="3">
                <v-select
                    v-model="statusId"
                    :items="statuses"
                    item-text="name"
                    item-value="id"
                    label="Статус объявления"
                    single-line
                    @change="getList(1)"
                ></v-select>
            </v-col>
        </v-row>
        <v-data-table
        :headers="headers"
        :items="list"
        :items-per-page="-1"
        class="elevation-1"
        :loading="loading"
        :options.sync="options"
        page.sync="1"
        loading-text="Загрузка..."
        :server-items-length="totalCount"
        :footer-props="{
        itemsPerPageOptions: [10, 50, 100, 150],
        disableItemsPerPage: false,
        'items-per-page-text':'Элементов на странице:',
        'items-per-page-all-text':'Все'
        }"
        >
            <template v-slot:top>
                <v-dialog
                v-model="dialogEdit"
                max-width="500px"
                >

                    <v-card>
                        <v-card-title>
                            <span class="text-h5">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card color="basil" flat>
                            <v-card-text>
                                <v-text-field label="Заголовок" v-model="editedItem.name"></v-text-field>
                            </v-card-text>
                        </v-card>
                        <v-card color="basil" flat>
                            <v-card-text>
                                <v-textarea label="Описание" v-model="editedItem.description"></v-textarea>
                            </v-card-text>
                        </v-card>
                        <v-card color="basil" flat>
                            <v-card-text>
                                <v-text-field label="Номер телефона" v-model="editedItem.phone"></v-text-field>
                            </v-card-text>
                        </v-card>
                        <v-card color="basil" flat>
                            <v-card-text>
                                <v-text-field label="Адрес" v-model="editedItem.address"></v-text-field>
                            </v-card-text>
                        </v-card>
                        <v-card color="basil" flat>
                            <v-card-text>
                                <yandex-map
                                    :coords="[editedItem.location.x, editedItem.location.y]"
                                    :zoom="18"
                                    :controls="[]"
                                >
                                <ymap-marker
                                    :coords="[editedItem.location.x, editedItem.location.y]"
                                    marker-id="123"
                                    hint-content="Адрес"
                                />
                                </yandex-map>
                            </v-card-text>
                        </v-card>
                        <v-card color="basil" flat>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field label="Широта" v-model="editedItem.location.x"></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field label="Долгота" v-model="editedItem.location.y"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <v-card color="basil" flat>
                            <v-card-text>
                                <v-text-field label="Цена" v-model="editedItem.price"></v-text-field>
                            </v-card-text>
                        </v-card>
                        <v-row v-if="editedItem.pub"> 
                            <v-col
                                cols="12"
                                :sm="12"
                                :md="12"
                            >
                                <v-card color="basil" flat>
                                    <v-card-text>
                                        <v-text-field
                                            v-model="editedItem.pub"
                                            disabled
                                            label="Дата публикации"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                        ></v-text-field>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col
                                cols="6"
                                :sm="6"
                                :md="6"
                            >
                                <v-card color="basil" flat>
                                    <v-card-text>
                                        <v-text-field
                                            v-model="editedItem.start"
                                            disabled
                                            label="Дата начала"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                        ></v-text-field>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col
                                cols="6"
                                :sm="6"
                                :md="6"
                            >
                                <v-card color="basil" flat>
                                    <v-card-text>
                                        <v-text-field
                                            v-model="editedItem.end"
                                            disabled
                                            label="Дата окончания"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                        ></v-text-field>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            
                        </v-row>
                        <v-card color="basil" flat>
                            <v-card-text>
                                <v-file-input label="Изображения объявления" accept="image/jpeg, image/png" v-model="editedItem.newImages" :clearable="false" multiple @change="updateImagesList" ref="formImages"></v-file-input>
                                <div class="d-flex flex-column justify-space-between align-center" v-if="editedItem.id > 0">
                                    <v-container>
                                        <v-row>
                                            <v-col
                                            v-for="singleImage in editedItem.images"
                                            :key="singleImage.index"
                                            class="d-flex child-flex image"
                                            cols="4"
                                            >
                                                <v-img
                                                    :src="$store.state.api + singleImage.image"
                                                    aspect-ratio="1"
                                                    contain
                                                    class="grey lighten-2"
                                                >
                                                </v-img>
                                                <v-icon
                                                    small
                                                    dark
                                                    class="delete-ico"
                                                    @click="setToDelete(singleImage.id)"
                                                >
                                                    mdi-delete
                                                </v-icon>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </div>
                            </v-card-text>
                        </v-card>
                        <v-card color="basil" flat>
                            <v-card-text>
                                <v-select label="Категория" v-model="editedItem.category" :items="categories" return-object :menu-props="{ maxHeight: '400' }" persistent-hint item-text="name"></v-select>
                            </v-card-text>
                        </v-card>
                        <v-card color="basil" flat>
                            <v-card-text>
                                <v-select label="Статус объявления" v-model="editedItem.statusId" :items="statuses" :menu-props="{ maxHeight: '400' }" persistent-hint item-text="name" item-value="id"></v-select>
                            </v-card-text>
                        </v-card>
                        <v-card color="basil" flat>
                            <v-card-text>
                                <v-select label="Города" v-model="editedItem.cities" :items="cities" :menu-props="{ maxHeight: '400' }" return-object multiple persistent-hint item-text="name"></v-select>
                            </v-card-text>
                        </v-card>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="closeEdit"
                            >
                                Отмена
                            </v-btn>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="save"
                            >
                                Сохранить
                            </v-btn>
                        </v-card-actions>
                    </v-card>

                    <file-uploader ref="uploader" />
                </v-dialog>

                <v-dialog
                v-model="dialogRemove"
                max-width="500px"
                >
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">Удаление объявления</span>
                        </v-card-title>

                        <v-card-text>Вы действительно хотите удалить указанное объявление?</v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="closeRemove"
                            >
                                Отмена
                            </v-btn>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="removeItem(itemToRemove)"
                            >
                                Удалить
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>

            <template v-slot:item.actions="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                    title="Изменить объявление"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    small
                    class="mr-2"
                    @click="openRemoveDialog(item)"
                    title="Удалить объявление"
                >
                    mdi-delete
                </v-icon>
            </template>
            <template v-slot:item.hide="{ item }"><v-icon small v-if="item.hide">mdi-check-bold</v-icon></template>
        </v-data-table>
    </div>
</template>

<script>
import FileUploader from "@c/FileUploader.vue";
import { yandexMap, ymapMarker } from 'vue-yandex-maps';

export default {
    name: "Ads",
    components: {
        FileUploader,
        yandexMap,
        ymapMarker
    },
    data() {
        return {
            list: [],
            cities: [],
            statusId: 2,
            statuses: [],
            categories: [],
            periods: [],
            user: 0,
            page: 1,
            totalCount: -1,
            hasNext: false,
            deletedImages: [],
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: ['name']
            },
            headers: [
                {
                    value: "id",
                    text: "ID",
                    sortable: false,
                },
                {
                    value: "name",
                    text: "Заголовок",
                    sortable: false,
                },
                {
                    value: "category_string",
                    text: "Категория",
                    sortable: false,
                },
                {
                    value: "city_string",
                    text: "Города",
                    sortable: false,
                },
                {
                    value: "pub",
                    text: "Дата",
                    sortable: false,
                },
                {
                    value: "user_string",
                    text: "Пользователь",
                    sortable: false,
                },
                {
                    value: 'actions',
                    text: 'Действия',
                    sortable: false,
                    align: 'end',
                },
            ],
            loading: false,
            pg: 1,
            dialogEdit: false,
            editedIndex: -1,
            editedItem: {
                id: -1,
                name: '',
                description: '',
                phone: '',
                address: '',
                location: {
                    x: 0,
                    y: 0
                },
                price: 0,
                category: {},
                cities: [],
                images: [],
                periodId: 0,
                statusId: 0,
                pub: '',
                start: '',
                end: '',
                newImages: [],
                userId: -1,
            },
            // Дефолтный итем для того, чтобы не выдавало ошибок при сохранении
            // объявления и подследующем обнулении location.
            defaultItem: {
                location: {
                    x: 0,
                    y: 0
                }
            },
            newImg: [],
            dialogRemove: false,
            itemToRemove: -1,
            newId: 0
        };
    },
    watch: {
        async options(pagination) {
            this.page = pagination.page;
            await this.getList(pagination.page);
        },
        dialogEdit (val) {
            val || this.closeEdit();
        },
        dialogRemove (val) {
            val || this.closeRemove();
        }
    },
    mounted() {
        this.getStatuses();
        this.getCities();
        this.getCategories();
        this.getUser();
    },
    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'Новое объявление' : 'Редактирование объявления #' + this.editedItem.id;
        },
    },
    methods: {
        setToDelete(id) {
            this.deletedImages.push(id);
            this.editedItem.images = this.editedItem.images.filter(item => item.id != id);
        },
        async getList(pg = 1) {
            this.loading = true;

            try {
                const { hasNext, list, totalCount } = await this.$api(`/rk?${new URLSearchParams({
                    fullFields: 1,
                    pg: pg,
                    on: this.options.itemsPerPage,
                    statusId: this.statusId
                })}`);

                list.forEach((singleAd, index) => {
                    let city_names = [];

                    singleAd.cities.forEach((city) => {
                        city_names.push(city.name);
                    });

                    list[index].city_string = city_names.join(', ');
                    list[index].category_string = singleAd.category.name;

                    list[index].user_string = "[" + singleAd.user.id + "] " + singleAd.user.login;
                });

                this.list = list;
                this.hasNext = hasNext;
                this.options.page = pg;
                this.totalCount = totalCount;
            } catch (e) {
                this.$error(e.message);
            }

            this.loading = false;
        },
        async getCities() {
            try {
                const { list } = await this.$api("/cities");

                this.cities = list;
            } catch (e) {
                this.$error(e.message);
            }
        },
        async getStatuses() {
            try {
                const { list } = await this.$api("/rk_statuses");

                this.statuses = list;
            } catch (e) {
                this.$error(e.message);
            }
        },
        async getPeriods() {
            try {
                const { list } = await this.$api("/rk_periods");

                this.periods = list;
            } catch (e) {
                this.$error(e.message);
            }
        },
        async getCategories() {
            try {
                const { list } = await this.$api(`/rk_categories?${new URLSearchParams({
                    allCategories: true,
                })}`);

                this.categories = list;
            } catch (e) {
                this.$error(e.message);
            }
        },
        async getUser() {
            try {
                let userId = await this.$api("/current");
                this.user = userId.id;
            } catch (e) {
                this.$error(e.message);
            }
        },
        async editItem (item) {
            this.editedIndex = await this.list.indexOf(item);
            
            try {
                let r = await this.$api(`/rk/${item.id}`);
                this.editedItem = Object.assign({}, {
                    statusId: r.statusId,
                    periodId: r.period.id,
                    category: r.category,
                    cities: r.cities.map(item => item.id)
                }, r);
                this.dialogEdit = true;
            } catch (e) {
                this.$error(e.message);
            }
        },
        openRemoveDialog (item) {
            this.itemToRemove = item;
            this.dialogRemove = true;
        },
        async removeItem (item) {
            try {
                let r = await this.$api(`/rk/${item.id}`, {
                    method: "DELETE"
                });
                if (!r.error) {
                    this.getList(1);
                    this.closeRemove();
                }
            } catch (e) {
                this.$error(e.message);
            }
        },
        closeRemove () {
            this.dialogRemove = false;
            this.$nextTick(() => {
                this.itemToRemove = -1;
            });
        },
        closeEdit () {
            this.dialogEdit = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        updateImagesList(files) {
            for (let f of files) {
                const obj = {
                    id: --this.newId,
                    file: f,
                };
                this.newImg.push(obj);
            }
        },
        async uploadImages() {
            if (this.newImg.length > 0) {
                const needUpload = [];

                for (let l of this.newImg) {
                    if (l.file) {
                        l.file.id = l.id;
                        needUpload.push(l.file);
                        delete l.file;
                    }
                }

                if (needUpload.length) {
                    const files = await this.$refs.uploader.upload(this.editedItem.newImages);
                
                    for (let l of this.newImg) {
                        for (let f of files) {
                            if (f.id == l.id) {
                                l.tmp = f.tmp;
                                l.name = f.name;
                                l.size = Math.ceil(f.size / 1024 / 1024);
                                l.type = f.type.split('/')[1];
                            }
                        }
                    }
                }

                let filesUploaded = [];

                for (let l of this.newImg) {
                    filesUploaded.push(l.tmp);
                }

                return filesUploaded;
            }
        },
        async save () {
            let imageFiles = [];

            if (this.newImg) {
                imageFiles = await this.uploadImages();
            }

            let c_ids = [];
            this.editedItem.cities.forEach((city) => {
                if (city.id) {
                    c_ids.push(city.id);
                } else {
                    c_ids.push(city);
                }
            });

            if (this.deletedImages.length > 0) {
                try {
                    let r = await this.$api("/rk/images/" + this.editedItem.id, {
                        method: "DELETE",
                        body: {
                            image_ids: this.deletedImages
                        },
                    });
                    if (!r.error) {
                        this.deletedImages = [];
                    }
                } catch (e) {
                    this.$error(e.message);
                }
            }

            if (this.editedIndex > -1) {
                try {
                    let r = await this.$api("/admin/rk/" + this.editedItem.id, {
                        method: "PATCH",
                        body: {
                            name: this.editedItem.name,
                            description: this.editedItem.description,
                            phone: this.editedItem.phone,
                            location: this.editedItem.location,
                            images: imageFiles ? imageFiles : [],
                            price: this.editedItem.price,
                            statusId: this.editedItem.statusId,
                            address: this.editedItem.address,
                            periodId: this.editedItem.periodId,
                            city_ids: c_ids,
                            categoryId: this.editedItem.category.id,
                            user_id: this.user,
                        },
                    });
                    if (!r.error) {
                        this.getList();
                        this.closeEdit();
                    }
                } catch (e) {
                    this.$error(e.message);
                }
            }
            this.newImg = [];
        }
    }
};
</script>

<style scoped>
    .ymap-container {
        width: 100%;
        height: 300px;
    }
    .image {
        position: relative;
    }
    .delete-ico {
        position: absolute;
        top: 20px;
        right: 20px;
    }
    .delete-ico:hover {
        cursor: pointer;
    }
</style>