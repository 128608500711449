import VuetifyDialog from 'vuetify-dialog';
import 'vuetify-dialog/dist/vuetify-dialog.css';
export default {
    install(Vue, { vuetify }) {
        Vue.use(VuetifyDialog, {
            context: {
                vuetify,
            },
        });
        ['info', 'success', 'warning', 'error'].forEach(t => {
            Vue.prototype[`$${t}`] = function (message, options = {}) {
                return this.$dialog.notify[t](message, { position: "bottom-right", ...options });
            };
        });

        Vue.prototype.$confirm = function (options = {}) {
            return this.$dialog.confirm({
                title: 'Внимание',
                text: 'Подтвердите действие',
                actions: {
                    false: {
                        text: 'Отмена',
                        color: 'error',
                    },
                    true: {
                        text: 'Продолжить',
                        color: 'primary',
                    }
                },
                ...options,
            });
        };
    }
};
