export default {
    namespaced: true,
    state: {
        token: localStorage.getItem('token'),
        admin: false,
    },
    mutations: {
        token: (state, token) => {
            state.token = token;
            if (token) {
                localStorage.setItem('token', token);
            } else {
                localStorage.removeItem('token');
            }
        },
        admin: (state, admin) => state.admin = admin,
    }
};
