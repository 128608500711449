<template>
    <v-data-table
        :headers="headers"
        :items="list"
        :items-per-page="-1"
        class="elevation-1"
        :loading="loading"
        :options.sync="options"
        page.sync="1"
        loading-text="Загрузка..."
        :server-items-length="totalCount"
        :footer-props="{
        itemsPerPageOptions: [10, 50, 100, 150],
        disableItemsPerPage: false,
        'items-per-page-text':'Элементов на странице:',
        'items-per-page-all-text':'Все'
        }"
    >
         <template v-slot:top>
            <v-dialog
            v-model="dialogEdit"
            max-width="500px"
            >
                <v-card>
                    <v-card-title>
                        <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card color="basil" flat>
                        <v-card-text>
                            <v-text-field label="Заголовок" v-model="editedItem.name"></v-text-field>
                        </v-card-text>
                    </v-card>
                    <v-card color="basil" flat>
                        <v-card-text>
                            <v-text-field label="Номер телефона" v-model="editedItem.phone"></v-text-field>
                        </v-card-text>
                    </v-card>
                    <v-card color="basil" flat>
                        <v-card-text>
                            <v-text-field label="Цена" v-model="editedItem.price"></v-text-field>
                        </v-card-text>
                    </v-card>
                    <v-card color="basil" flat>
                        <v-card-text>
                            <v-text-field label="Почта" v-model="editedItem.email"></v-text-field>
                        </v-card-text>
                    </v-card>
                    <v-card color="basil" flat>
                        <v-card-text>
                            <v-textarea label="Комментарий" v-model="editedItem.text"></v-textarea>
                        </v-card-text>
                    </v-card>
                    <v-card color="basil" flat>
                        <v-card-text class="d-inline-flex flex-column flex-wrap justify-space-between align-items-center">
                            <div>
                                <div v-for="(file, index) in editedItem.files" :key="file.id+index+file.sort" class="d-inline-flex file">
                                    <a :href="`${API_URL}${file.link}`" target="_blank" v-if="isPdf(file.typeFile)">
                                        <v-icon>
                                            mdi-file-pdf-box
                                        </v-icon>
                                        PDF файл
                                    </a>
                                    <a :href="`${API_URL}${file.link}`" target="_blank" v-else-if="isArch(file.typeFile)">
                                        <v-icon>
                                            mdi-archive
                                        </v-icon>
                                        Архив
                                    </a>
                                    <a :href="`${API_URL}${file.link}`" target="_blank" v-else-if="isMp4(file.typeFile)">
                                        <v-icon>
                                            mdi-file-video
                                        </v-icon>
                                        Видео
                                    </a>
                                    <a :href="`${API_URL}${file.link}`" target="_blank" v-else-if="isImg(file.typeFile)">
                                        <v-icon>
                                            mdi-file-image
                                        </v-icon>
                                        Изображение
                                    </a>
                                    <a :href="`${API_URL}${file.link}`" target="_blank" v-else>
                                        <v-icon>
                                            mdi-file-document
                                        </v-icon>
                                        Файл
                                    </a>
                                </div>
                            </div>
                            <div class="d-flex" v-if="editedItem.id > 0">
                                Прикреплений: {{ editedItem.files.length }}
                            </div>
                        </v-card-text>
                    </v-card>
                    <v-card color="basil" flat>
                        <v-card-text>
                            <v-select label="Типы" v-model="editedItem.type" :items="types" :menu-props="{ maxHeight: '400' }" persistent-hint item-text="name" item-value="id"></v-select>
                        </v-card-text>
                    </v-card>
                    <v-card color="basil" flat>
                        <v-card-text>
                            <v-select label="Статус" v-model="editedItem.status" :items="statuses" :menu-props="{ maxHeight: '400' }" persistent-hint item-text="name" item-value="id"></v-select>
                        </v-card-text>
                    </v-card>
                    <v-card v-if="editedItem.status == 3" color="basil" flat>
                        <v-col
                            cols="12"
                            :sm="12"
                            :md="12"
                        >
                            <v-dialog
                                ref="dialog1"
                                v-model="dateDialogStart"
                                :return-value.sync="editedItem.start"
                                persistent
                                width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="editedItem.start"
                                    label="Начало размещения"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="editedItem.start"
                                    scrollable
                                    locale="ru-RU"
                                >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="dateDialogStart = false"
                                >
                                    Отмена
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.dialog1.save(editedItem.start)"
                                >
                                    Ок
                                </v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </v-col>
                        <v-col
                            cols="12"
                            :sm="12"
                            :md="12"
                        >
                            <v-dialog
                                ref="dialog2"
                                v-model="dateDialogEnd"
                                :return-value.sync="editedItem.end"
                                persistent
                                width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="editedItem.end"
                                    label="Конец размещения"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="editedItem.end"
                                    scrollable
                                    locale="ru-RU"
                                >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="dateDialogEnd = false"
                                >
                                    Отмена
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.dialog2.save(editedItem.end)"
                                >
                                    Ок
                                </v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </v-col>
                    </v-card>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="closeEdit"
                        >
                            Отмена
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="save"
                        >
                            Сохранить
                        </v-btn>
                    </v-card-actions>
                </v-card>

                <file-uploader ref="uploader" />
            </v-dialog>
        </template>

        <template v-slot:item.actions="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
                title="Изменить заказ"
            >
                mdi-pencil
            </v-icon>
        </template>
    </v-data-table>
</template>

<script>
import FileUploader from "@c/FileUploader.vue";
export default {
    name: "Orders",
    components: {
        FileUploader
    },
    data() {
        return {
            list: [],
            types: [],
            statuses: [],
            page: 1,
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: ['id']
            },
            API_URL: process.env.VUE_APP_API,
            archTypes: [
                'zip',
                'x-rar-compressed',
                'vnd.rar',
                'x-7z-compressed'
            ],
            imgTypes: [
                'jpg',
                'jpeg',
                'png'
            ],
            headers: [
                {
                    value: "id",
                    text: "ID",
                    sortable: false,
                },
                {
                    value: "name",
                    text: "Заголовок",
                    sortable: false,
                },
                {
                    value: "phone",
                    text: "Телефон",
                    sortable: false,
                },
                {
                    value: "price",
                    text: "Цена",
                    sortable: false,
                },
                {
                    value: "created",
                    text: "Создан",
                    sortable: false,
                },
                {
                    value: "start",
                    text: "Начало",
                    sortable: false,
                },
                {
                    value: "end",
                    text: "Конец",
                    sortable: false,
                },
                {
                    value: "status.name",
                    text: "Статус",
                    sortable: false,
                },
                {
                    value: "type.name",
                    text: "Тип",
                    sortable: false,
                },
                {
                    value: 'actions',
                    text: 'Действия',
                    sortable: false,
                    align: 'end',
                },
            ],
            loading: false,
            pg: 1,
            totalCount: -1,
            hasNext: false,
            dialogEdit: false,
            editedIndex: -1,
            dateDialogStart: false,
            dateDialogEnd: false,
            editedItem: {
                id: -1,
                name: '',
                phone: '',
                created: '',
                text: '',
                status: {},
                price: 0,
                email: '',
                start: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                end: (new Date((Date.now() + 1*24*60*60*1000) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                type: {},
                files: []
            },
            newId: 0,
        };
    },
    watch: {
        options(pagination) {
            this.page = pagination.page;
            this.getList(pagination.page);
        },
        dialogEdit (val) {
            val || this.closeEdit();
        },
    },
    mounted() {
        this.getTypes();
        this.getStatuses();
    },
    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'Новый заказ' : 'Редактирование заказа #' + this.editedItem.id;
        },
    },
    methods: {
        async getList(pg = 1) {
            this.loading = true;

            try {
                const { list, totalCount, hasNext } = await this.$api(`/rk_orders?${new URLSearchParams({
                    pg: pg,
                    on: this.options.itemsPerPage
                })}`);

                list.forEach((singleOrder, index) => {
                    list[index].type_string = singleOrder.type.name;
                });

                this.list = list;
                this.hasNext = hasNext;
                this.pg = pg;
                this.totalCount = totalCount;
            } catch (e) {
                this.$error(e.message);
            }

            this.loading = false;
        },
        isPdf (type) {
            return type === 'pdf'; 
        },
        isArch (type) {
            return this.archTypes.includes(type); 
        },
        isMp4 (type) {
            return type === 'mp4'; 
        },
        isImg (type) {
            return this.imgTypes.includes(type); 
        },
        async getTypes() {
            try {
                const { list } = await this.$api("/rk_orders_types");

                this.types = list;
            } catch (e) {
                this.$error(e.message);
            }
        },
        async getStatuses() {
            try {
                const { list } = await this.$api("/rk_orders_statuses");

                this.statuses = list;
            } catch (e) {
                this.$error(e.message);
            }
        },
        async editItem (item) {
            this.editedIndex = await this.list.indexOf(item);
            
            try {
                let r = await this.$api(`/rk_orders/${item.id}`);
                this.editedItem = Object.assign({}, r, {
                    status: r.status.id,
                    type: r.type.id,
                    start: r.start ?? this.editedItem.start,
                    end: r.end ?? this.editedItem.end
                });
                this.dialogEdit = true;
            } catch (e) {
                this.$error(e.message);
            }
        },
        closeEdit () {
            this.dialogEdit = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        async save () {
            if (this.editedIndex > -1) {
                try {
                    let r = await this.$api("/admin/rk_orders/" + this.editedItem.id, {
                        method: "PUT",
                        body: {
                            name: this.editedItem.name,
                            phone: this.editedItem.phone,
                            statusId: this.editedItem.status,
                            start: this.editedItem.start,
                            end: this.editedItem.end,
                            email: this.editedItem.email,
                            price: this.editedItem.price,
                            typeAdId: this.editedItem.type
                        },
                    });
                    if (!r.error) {
                        this.getList();
                        this.closeEdit();
                    }
                } catch (e) {
                    this.$error(e);
                }
            }
        }
    }
};
</script>

<style scoped>
    .file {
        margin-right: 5px;
    }
    .file a {
        text-decoration: none;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .file a i {
        font-size: 50px !important;
    }
</style>