<template>
    <div>
        <v-row class="d-flex justify-end">
            <v-col cols="3">
                <v-select
                    v-model="statusId"
                    :items="statuses"
                    item-text="name"
                    item-value="id"
                    label="Статус карточки организации"
                    single-line
                    @change="getList(1)"
                ></v-select>
            </v-col>
        </v-row>
        <v-data-table
        :headers="headers"
        :items="list"
        :items-per-page="-1"
        class="elevation-1"
        :loading="loading"
        :options.sync="options"
        page.sync="1"
        loading-text="Загрузка..."
        :server-items-length="totalCount"
        :footer-props="{
        itemsPerPageOptions: [10, 50, 100, 150],
        disableItemsPerPage: false,
        'items-per-page-text':'Элементов на странице:',
        'items-per-page-all-text':'Все'
        }"
        >
            <template v-slot:top>
                <v-dialog
                v-model="dialogEdit"
                max-width="500px"
                >
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card color="basil" flat>
                            <v-card-text>
                                <v-text-field label="Заголовок" v-model="editedItem.name"></v-text-field>
                            </v-card-text>
                        </v-card>
                        <v-card color="basil" flat>
                            <v-card-text>
                                <v-textarea label="Описание" v-model="editedItem.description"></v-textarea>
                            </v-card-text>
                        </v-card>
                        <v-card color="basil" flat>
                            <v-card-text
                                v-for="(phone, index) in editedItem.phones"
                                :key="index+phone+index"
                            >
                                <v-text-field :label="`Номер телефона #${index + 1}`" v-model="editedItem.phones[index]"></v-text-field>
                            </v-card-text>
                        </v-card>
                        <v-card color="basil" flat>
                            <v-card-text>
                                <v-text-field label="Адрес" disabled v-model="getAddress"></v-text-field>
                            </v-card-text>
                        </v-card>
                        <v-card color="basil" flat>
                            <v-card-text>
                                <yandex-map
                                    :coords="[editedItem.address.location.x, editedItem.address.location.y]"
                                    :zoom="18"
                                    :controls="[]"
                                >
                                    <ymap-marker
                                        :coords="[editedItem.address.location.x, editedItem.address.location.y]"
                                        marker-id="123"
                                        hint-content="Адрес"
                                    />
                                </yandex-map> 
                            </v-card-text>
                        </v-card>
                        <v-card color="basil" flat>
                            <v-card-text>
                                <v-select
                                    label="Рабочие дни"
                                    v-model="editedItem.working.weekdays"
                                    :items="getdWeekdays"
                                    return-object
                                    :menu-props="{ maxHeight: '400' }"
                                    multiple
                                    persistent-hint
                                    item-text="short"
                                ></v-select>
                            </v-card-text>
                        </v-card>
                        <v-card color="basil" flat>
                            <v-card-text>
                                <v-dialog ref="showModalWorkingStart" v-model="showModalWorkingStart" :return-value.sync="editedItem.working.weekdaysHours.start" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="editedItem.working.weekdaysHours.start" label="Начало рабочего дня" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-time-picker
                                        v-if="showModalWorkingStart"
                                        v-model="editedItem.working.weekdaysHours.start"
                                        full-width
                                        format="24hr"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="showModalWorkingStart = false">Отмена</v-btn>
                                        <v-btn text color="primary" @click="$refs.showModalWorkingStart.save(editedItem.working.weekdaysHours.start)">Ок</v-btn>
                                    </v-time-picker>
                                </v-dialog>
                            </v-card-text>
                        </v-card>
                        <v-card color="basil" flat>
                            <v-card-text>
                                <v-dialog ref="showModalWorkingEnd" v-model="showModalWorkingEnd" :return-value.sync="editedItem.working.weekdaysHours.end" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="editedItem.working.weekdaysHours.end" label="Конец рабочего дня" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-time-picker
                                        v-if="showModalWorkingEnd"
                                        v-model="editedItem.working.weekdaysHours.end"
                                        full-width
                                        format="24hr"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="showModalWorkingEnd = false">Отмена</v-btn>
                                        <v-btn text color="primary" @click="$refs.showModalWorkingEnd.save(editedItem.working.weekdaysHours.end)">Ок</v-btn>
                                    </v-time-picker>
                                </v-dialog>
                            </v-card-text>
                        </v-card>
                        <v-card color="basil" flat>
                            <v-card-text>
                                <v-dialog ref="showModalLunchStart" v-model="showModalLunchStart" :return-value.sync="editedItem.working.lunchBreakHours.start" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="editedItem.working.lunchBreakHours.start" label="Начало обеденного перерыва" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-time-picker
                                        v-if="showModalLunchStart"
                                        v-model="editedItem.working.lunchBreakHours.start"
                                        full-width
                                        format="24hr"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="showModalLunchStart = false">Отмена</v-btn>
                                        <v-btn text color="primary" @click="$refs.showModalLunchStart.save(editedItem.working.lunchBreakHours.start)">Ок</v-btn>
                                    </v-time-picker>
                                </v-dialog>
                            </v-card-text>
                        </v-card>
                        <v-card color="basil" flat>
                            <v-card-text>
                                <v-dialog ref="showModalLunchEnd" v-model="showModalLunchEnd" :return-value.sync="editedItem.working.lunchBreakHours.end" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="editedItem.working.lunchBreakHours.end" label="Конец обеденного перерыва" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-time-picker
                                        v-if="showModalLunchEnd"
                                        v-model="editedItem.working.lunchBreakHours.end"
                                        full-width
                                        format="24hr"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="showModalLunchEnd = false">Отмена</v-btn>
                                        <v-btn text color="primary" @click="$refs.showModalLunchEnd.save(editedItem.working.lunchBreakHours.end)">Ок</v-btn>
                                    </v-time-picker>
                                </v-dialog>
                            </v-card-text>
                        </v-card>
                        <v-card color="basil" flat>
                            <v-card-text>
                                <v-select
                                    label="Выходные дни"
                                    v-model="editedItem.working.weekends"
                                    :items="getWeekends"
                                    return-object
                                    :menu-props="{ maxHeight: '400' }"
                                    multiple
                                    persistent-hint
                                    item-text="short"
                                ></v-select>
                            </v-card-text>
                        </v-card>
                        <v-card color="basil" flat>
                            <v-card-text>
                                <v-text-field
                                    v-model="editedItem.created"
                                    disabled
                                    label="Дата создания"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                ></v-text-field>
                            </v-card-text>
                        </v-card>
                        <v-card color="basil" flat>
                            <v-card-text>
                                <v-text-field label="Сайт" v-model="editedItem.site"></v-text-field>
                            </v-card-text>
                        </v-card>
                        <v-card color="basil" flat>
                            <v-card-text>
                                <v-select label="Категории" v-model="editedItem.categories" :items="categories" :menu-props="{ maxHeight: '400' }" multiple persistent-hint return-object item-text="name"></v-select>
                            </v-card-text>
                        </v-card>
                        <v-card color="basil" flat>
                            <v-card-text>
                                <v-select label="Статус" v-model="editedItem.status" :items="statuses" :menu-props="{ maxHeight: '400' }" persistent-hint return-object item-text="name"></v-select>
                            </v-card-text>
                        </v-card>
                        <v-card color="basil" flat>
                            <v-card-text>
                                <v-text-field
                                    v-model="getUserString"
                                    disabled
                                    label="Пользователь"
                                    readonly
                                ></v-text-field>
                            </v-card-text>
                        </v-card>
                        <v-card color="basil" flat>
                            <v-card-text>
                                <v-file-input
                                    v-if="editedItem.images.length < 6"
                                    label="Изображения объявления"
                                    accept="image/jpeg, image/png"
                                    v-model="newImages"
                                    :clearable="false"
                                    @click="$refs.formImages.blur()"
                                    multiple
                                    @change="updateImagesList"
                                    ref="formImages"
                                ></v-file-input>
                                <div class="d-flex flex-column justify-space-between align-center">
                                    <v-container>
                                        <v-row>
                                            <v-col
                                            v-for="singleImage in getPrivewImages"
                                            :key="singleImage.index"
                                            class="d-flex child-flex image"
                                            cols="4"
                                            >
                                                <v-img
                                                    v-if="singleImage.id < 0"
                                                    :src="singleImage.image"
                                                    aspect-ratio="1"
                                                    contain
                                                    class="grey lighten-2"
                                                >
                                                </v-img>
                                                <v-img
                                                    v-else
                                                    :src="$store.state.api + singleImage.image"
                                                    aspect-ratio="1"
                                                    contain
                                                    class="grey lighten-2"
                                                >
                                                </v-img>
                                                <v-icon
                                                    small
                                                    dark
                                                    class="delete-ico"
                                                    @click="remove(singleImage.id)"
                                                >
                                                    mdi-delete
                                                </v-icon>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </div>
                            </v-card-text>
                        </v-card>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="closeEdit"
                            >
                                Отмена
                            </v-btn>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="save"
                            >
                                Сохранить
                            </v-btn>
                        </v-card-actions>
                    </v-card>

                    <file-uploader ref="uploader" />
                </v-dialog>
            </template>

            <template v-slot:item.actions="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                    title="Изменить объявление"
                >
                    mdi-pencil
                </v-icon>
            </template>
            <template v-slot:item.hide="{ item }"><v-icon small v-if="item.hide">mdi-check-bold</v-icon></template>
        </v-data-table>
    </div>
</template>

<script>
import FileUploader from "@c/FileUploader.vue";
import { yandexMap, ymapMarker } from 'vue-yandex-maps';
export default {
    name: "OrganizationCards",
    components: {
        FileUploader,
        yandexMap,
        ymapMarker
    },
    data() {
        return {
            showModalWorkingStart: false,
            showModalWorkingEnd: false,
            showModalLunchStart: false,
            showModalLunchEnd: false,
            list: [],
            cities: [],
            statusId: 3,
            statuses: [],
            categories: [],
            daysWeek: [],
            user: 0,
            page: 1,
            totalCount: -1,
            hasNext: false,
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: ['name']
            },
            headers: [
                {
                    value: "id",
                    text: "ID",
                    sortable: false,
                },
                {
                    value: "name",
                    text: "Заголовок",
                    sortable: false,
                },
                {
                    value: "address_string",
                    text: "Адрес",
                    sortable: false,
                },
                {
                    value: "weekdays_string",
                    text: "Рабочие дни",
                    sortable: false,
                },
                {
                    value: "weekends_string",
                    text: "Выходные дни",
                    sortable: false,
                },
                {
                    value: "created",
                    text: "Дата",
                    sortable: false,
                },
                {
                    value: "user_string",
                    text: "Пользователь",
                    sortable: false,
                },
                {
                    value: 'actions',
                    text: 'Действия',
                    sortable: false,
                    align: 'end',
                },
            ],
            loading: false,
            pg: 1,
            dialogEdit: false,
            editedIndex: -1,
            editedItem: {
                id: -1,
                name: '',
                created: new Date(),
                working: {
                    weekdays: [],
                    weekends: [],
                    weekdaysHours: {
                        start: '08:00',
                        end: '17:00'
                    },
                    lunchBreakHours: {
                        start: '12:00',
                        end: '13:00'
                    },
                },
                description: '',
                categories: [],
                category: '',
                phones: [],
                status: {},
                user: {},
                address: {
                    city: {
                        id: -1,
                        city: ''
                    },
                    street: '',
                    location: {
                        x: null,
                        y: null
                    }
                },
                site: '',
                images: []
            },
            newImages: [],
            previewImages: [],
            defaultItem: {},
            newId: 0
        };
    },
    watch: {
        async options(pagination) {
            this.page = pagination.page;
            await this.getList(pagination.page);
        },
        dialogEdit (val) {
            val || this.closeEdit();
        }
    },
    mounted() {
        this.getDaysWeek();
        this.getStatuses();
        this.getCategories();
        this.getUser();
    },
    computed: {
        formTitle () {
            return 'Редактирование карточки огранизации #' + this.editedItem.id;
        },
        getAddress () {
            return `${this.editedItem.address.city.city}, ${this.editedItem.address.street}`;
        },
        getdWeekdays () {
            return this.daysWeek.filter(item => !this.editedItem.working.weekends.map(item => item.id).includes(item.id));
        },
        getWeekends () {
            return this.daysWeek.filter(item => !this.editedItem.working.weekdays.map(item => item.id).includes(item.id));
        },
        getUserString () {
            return `[${this.editedItem.user.id}] ${this.editedItem.user.login}`;
        },
        getPrivewImages () {
            return this.previewImages;
        }
    },
    methods: {
        remove(id) {
            this.editedItem.images = this.editedItem.images.filter(item => item.id != id);
            this.previewImages = this.previewImages.filter(item => item.id != id);
        },
        async getList(pg = 1) {
            this.loading = true;

            try {
                const { hasNext, list, totalCount } = await this.$api(`/organizations_cards?${new URLSearchParams({
                    pg: pg,
                    on: this.options.itemsPerPage,
                    statusId: this.statusId
                })}`);

                list.forEach((single, index) => {
                    let address_string = '';
                    let weekdays_string = '';
                    let weekends_string = '';
                    let user_string = '';

                    address_string = `${single.address.city.city}, ${single.address.street}`;
                    weekdays_string = single.working.weekdays
                        .map(item => item.short).join(', ');
                    weekends_string = single.working.weekends
                        .map(item => item.short).join(', ');
                    user_string = `[${single.user.id}] ${single.user.login}`;

                    list[index].address_string = address_string;
                    list[index].weekdays_string = weekdays_string;
                    list[index].weekends_string = weekends_string;
                    list[index].user_string = user_string;
                });

                this.list = list;
                this.hasNext = hasNext;
                this.options.page = pg;
                this.totalCount = totalCount;
            } catch (e) {
                this.$error(e.message);
            }

            this.loading = false;
        },
        async getStatuses() {
            try {
                const { list } = await this.$api("/oc_statuses");

                this.statuses = list;
            } catch (e) {
                this.$error(e.message);
            }
        },
        async getDaysWeek() {
            try {
                const { list } = await this.$api("/organizations_cards/daysweek");

                this.daysWeek = list;
            } catch (e) {
                this.$error(e.message);
            }
        },
        async getCategories() {
            try {
                const { list } = await this.$api("/oc_categories");

                this.categories = list;
            } catch (e) {
                this.$error(e.message);
            }
        },
        async getUser() {
            try {
                let userId = await this.$api("/current");
                this.user = userId.id;
            } catch (e) {
                this.$error(e.message);
            }
        },
        async editItem (item) {
            this.editedIndex = await this.list.indexOf(item);
            this.defaultItem = Object.assign({}, this.editedItem);
            try {
                let r = await this.$api(`/organizations_cards/${item.id}`);
                this.editedItem = Object.assign({}, r);
                this.dialogEdit = true;
            } catch (e) {
                this.$error(e.message);
            }
            this.updatePreviewImages();
        },
        closeEdit () {
            this.dialogEdit = false;
            this.previewImages = [];
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        updatePreviewImages () {
            this.editedItem.images.forEach((item) => {
                if (!this.previewImages.map(item => item.id).includes(item.id)) {
                    if (item.id < 0) {
                        const reader = new FileReader();
                        reader.readAsDataURL(item.image);
                        reader.onload = () => {
                            this.previewImages.push({ id: item.id, image: reader.result });
                        };
                    } else {
                        this.previewImages.push(item);
                    }
                }
            });
        },
        updateImagesList(files) {
            for (let f of files) {
                const obj = {
                    id: --this.newId,
                    image: f,
                };
                this.editedItem.images.push(obj);
            }
            
            this.updatePreviewImages();
            this.newImages = [];
        },
        async uploadImages() {
            if (this.editedItem.images) {
                if (this.editedItem.images.length > 0) {
                    let needUpload = [];
                    this.editedItem.images.forEach((item) => {
                        if (item.id < 0 && item.image instanceof File) {
                            item.image.id = item.id;
                            needUpload.push(item.image);
                        }
                    });

                    if (needUpload.length) {
                        const images = await this.$refs.uploader.upload(needUpload);
                    
                        this.editedItem.images.forEach((item, index) => {
                            images.forEach(uploaded => {
                                if (uploaded.id === item.id) {
                                    this.editedItem.images[index] = Object.assign({}, {
                                        id: item.id,
                                        image: uploaded.tmp
                                    });
                                }
                            });
                        });
                    }

                    needUpload = [];
                    return this.editedItem.images;
                }
            }
            return false;
        },
        async save () {
            await this.uploadImages().then(async (imageFiles) => {
                if (this.editedItem.images.length < 1) {
                    this.$error('Выберите хотя бы одно изображение');
                    return;
                }
                try {
                    let r = await this.$api("/admin/organizations_cards/" + this.editedItem.id, {
                        method: "PATCH",
                        body: {
                            name: this.editedItem.name,
                            description: this.editedItem.description,
                            phones: this.editedItem.phones.map(item => item),
                            address: this.editedItem.address,
                            statusId: this.editedItem.status.id,
                            categoryIds: this.editedItem.categories.map(item => item.id),
                            images: imageFiles,
                            site: this.editedItem.site,
                            working: this.editedItem.working
                        },
                    });
                    if (!r.error) {
                        this.previewImages = [];
                        this.getList();
                        this.closeEdit();
                    }
                } catch (e) {
                    this.$error(e.message);
                }
            });
        }
    }
};
</script>

<style scoped>
    .ymap-container {
        width: 100%;
        height: 300px;
    }
    .image {
        position: relative;
    }
    .delete-ico {
        position: absolute;
        top: 20px;
        right: 20px;
    }
    .delete-ico:hover {
        cursor: pointer;
    }
</style>