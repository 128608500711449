<template>
    <div>
        <v-row class="d-flex justify-end">
            <v-col cols="3">
                <v-select
                    v-model="commentStatusId"
                    :items="getCommentStaues"
                    item-text="name"
                    item-value="id"
                    label="Статус комментариев"
                    single-line
                    @change="getByStatus()"
                ></v-select>
            </v-col>
        </v-row>
        <v-data-table
        :headers="headers"
        :items="list"
        :items-per-page="-1"
        class="elevation-1"
        :loading="loading"
        :options.sync="options"
        page.sync="1"
        loading-text="Загрузка..."
        :server-items-length="totalCount"
        :footer-props="{
        itemsPerPageOptions: [10, 50, 100, 150],
        disableItemsPerPage: false,
        'items-per-page-text':'Элементов на странице:',
        'items-per-page-all-text':'Все'
        }"
        >
            <template v-slot:top>
                <v-dialog
                v-model="dialogEdit"
                max-width="500px"
                >
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col
                                        cols="12"
                                        v-if="editedItem.name"
                                    >
                                        <v-text-field label="Имя" v-model="editedItem.name"></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        v-if="editedItem.email"
                                    >
                                        <v-text-field label="Почта" v-model="editedItem.email"></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-textarea label="Текст комментария" v-model="editedItem.content"></v-textarea>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-select label="Статус комментария" v-model="editedItem.status" :items="statuses" :menu-props="{ maxHeight: '400' }" persistent-hint item-text="name" item-value="id"></v-select>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="closeEdit"
                            >
                                Отмена
                            </v-btn>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="save"
                            >
                                Сохранить
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog
                v-model="dialogRemove"
                max-width="500px"
                >
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">Удаление комментария</span>
                        </v-card-title>

                        <v-card-text>Вы действительно хотите удалить указанный комментарий?</v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="closeRemove"
                            >
                                Отмена
                            </v-btn>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="removeItem(itemToRemove)"
                            >
                                Удалить
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>

            <template v-slot:item.actions="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                    title="Изменить комментарий"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    small
                    class="mr-2"
                    @click="openRemoveDialog(item)"
                    title="Удалить комментарий"
                >
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>
    </div>
</template>

<script>
export default {
    name: "Comments",
    data() {
        return {
            list: [],
            statuses: [],
            user: 0,
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: ['id']
            },
            headers: [
                {
                    value: "id",
                    text: "ID",
                    sortable: false,
                },
                {
                    value: "news.string",
                    text: "Новость",
                    sortable: false,
                },
                {
                    value: "user.string",
                    text: "Пользователь",
                    sortable: false,
                },
                {
                    value: "status.name",
                    text: "Статус",
                    sortable: false,
                },
                {
                    value: "content",
                    text: "Текст",
                    sortable: false,
                },
                {
                    value: "datePub",
                    text: "Дата",
                    sortable: false,
                },
                {
                    value: 'actions',
                    text: 'Действия',
                    sortable: false,
                    align: 'end',
                },
            ],
            loading: false,
            dialogEdit: false,
            page: 1,
            commentStatusId: 0,
            totalCount: -1,
            hasNext: false,
            editedIndex: -1,
            editedItem: {
                id: 0,
                user: {},
                name: '',
                email: '',
                content: '',
                datePub: '',
                news: {},
                status: {}
            },
            dialogRemove: false,
            itemToRemove: -1
        };
    },
    watch: {
        options(pagination) {
            this.page = pagination.page;
            this.getList(pagination.page);
        },
        dialogEdit (val) {
            val || this.closeEdit();
        },
    },
    mounted() {
        this.getStatuses();
        this.getUser();
    },
    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'Новый комментарий' : 'Редактирование комментария #' + this.editedItem.id;
        },
        getCommentStaues() {
            let statuses = this.statuses;
            statuses.unshift({ id: 0, name: 'Все комментарии' });
            return statuses;
        }
    },
    methods: {
        async getByStatus() {
            this.page = 1;
            this.options.page = 1;
            await this.getList(this.page);
        },
        async getList(pg = 1) {
            this.loading = true;

            try {
                const { list, hasNext, totalCount  } = await this.$api(`/admin/news_comments?${new URLSearchParams({
                    pg: pg,
                    on: this.options.itemsPerPage,
                    statusId: this.commentStatusId
                })}`);

                list.forEach((comment, index) => {
                    list[index].news.string = list[index].news.name + " [" + list[index].news.id + "]";
                    if (comment.user?.id) {
                        const status = list[index].user.status;
                        list[index].user.string = list[index].user.login + " [" + (status ? status : list[index].user.id) + "]";
                    }
                });

                this.list = list;
                this.hasNext = hasNext;
                this.totalCount = totalCount;
            } catch (e) {
                this.$error(e.message);
            }

            this.loading = false;
        },
        async getStatuses() {
            try {
                const { list } = await this.$api("/admin/news_comment_status");

                this.statuses = list;
            } catch (e) {
                this.$error(e.message);
            }
        },
        async getUser() {
            try {
                let userId = await this.$api("/current");
                this.user = {
                    id: userId.id
                };
            } catch (e) {
                this.$error(e.message);
            }
        },
        editItem (item) {
            this.editedIndex = this.list.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogEdit = true;
        },
        async removeItem (item) {
            try {
                let r = await this.$api(`/admin/news_comments/${item.id}`, {
                    method: "DELETE"
                });
                if (!r.error) {
                    this.getList();
                    this.closeRemove();
                }
            } catch (e) {
                this.$error(e.message);
            }
        },
        closeRemove () {
            this.dialogRemove = false;
            this.$nextTick(() => {
                this.itemToRemove = -1;
            });
        },
        closeEdit () {
            this.dialogEdit = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        async save () {
            if (this.editedIndex > -1) {
                try {
                    let r = await this.$api("/admin/news_comments/" + this.editedItem.id, {
                        method: "PUT",
                        body: {
                            name: this.editedItem.name,
                            email: this.editedItem.email,
                            content: this.editedItem.content,
                            news_id: this.editedItem.news.id,
                            status_id: this.editedItem.status.id ? this.editedItem.status.id : this.editedItem.status,
                        }
                    });
                    if (!r.error) {
                        this.getList();
                        this.closeEdit();
                    }
                } catch (e) {
                    this.$error(e.message);
                }
            } else {
                try {
                    let r = await this.$api("/news_comments", {
                        method: "POST",
                        body: {
                            user_id: this.user,
                            content: this.editedItem.content,
                            news_id: this.editedItem.news.id,
                            status_id: this.editedItem.status.id ? this.editedItem.status.id : this.editedItem.status,
                        },
                    });
                    if (!r.error) {
                        this.getList();
                        this.closeEdit();
                    }
                } catch (e) {
                    this.$error(e.message);
                }
            }
        },
        openRemoveDialog (item) {
            this.itemToRemove = item;
            this.dialogRemove = true;
        },
    }
};
</script>
