/**
 * @type {import("vue-router").NavigationGuard}
 */
const guard = (to, from, next) => {
    document.title = '';
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    next();
};
export default guard;
