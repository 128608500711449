var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.list,"items-per-page":-1,"loading":_vm.loading,"loading-text":"Загрузка...","footer-props":{
  disableItemsPerPage: true,
  'items-per-page-text':'Элементов на странице:',
  'items-per-page-all-text':'Все'
}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Добавить категорию ")])]}}]),model:{value:(_vm.dialogEdit),callback:function ($$v) {_vm.dialogEdit=$$v},expression:"dialogEdit"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":12,"md":12}},[_c('v-text-field',{attrs:{"label":"Название категории"},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeEdit}},[_vm._v(" Отмена ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Сохранить ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogRemove),callback:function ($$v) {_vm.dialogRemove=$$v},expression:"dialogRemove"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Удаление категории")])]),_c('v-card-text',[_vm._v("Вы действительно хотите удалить указанную категорию?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeRemove}},[_vm._v(" Отмена ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.removeItem(_vm.itemToRemove)}}},[_vm._v(" Удалить ")])],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","title":"Изменить категорию"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","title":"Удалить категорию"},on:{"click":function($event){return _vm.openRemoveDialog(item)}}},[_vm._v(" mdi-delete ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }