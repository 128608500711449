/**
 * 
 * @param {RequestInfo} url 
 * @param {?RequestInit} options 
 * @returns {Promise<any>}
 */
export async function api(url, store, options = {}) {
    if ('body' in options) {
        options.body = JSON.stringify(options.body);
    }
    const headers = {
        'Content-type': 'application/json',
        'Accept': 'application/json',
    };
    if (store.state.auth.token) {
        headers.token = store.state.auth.token;
    }
    const r = await fetch(`${process.env.VUE_APP_API}${url}`, {
        headers,
        ...options,
    });
    if (!r.ok) {
        const { error, detail } = await r.json();
        if (error) {
            throw new Error(error);
        }
        if (detail) {
            throw new Error(detail);
        }

        throw new Error('Непредвиденная ошибка');
    }
    return r.json();
}

export default {
    install(Vue, { store }) {
        Vue.prototype.$api = (url, options = {}) => api(url, store, options);
    }
};