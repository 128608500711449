<template>
    <div>
        <v-row class="d-flex justify-end">
            <v-col cols="3">
                <v-select
                    v-model="roleId"
                    :items="types"
                    item-text="name"
                    item-value="id"
                    label="Группа пользователя"
                    single-line
                    @change="getList(1)"
                ></v-select>
            </v-col>
        </v-row>
        <v-data-table
        :headers="headers"
        :items="list"
        :items-per-page="-1"
        class="elevation-1"
        :loading="loading"
        :options.sync="options"
        page.sync="1"
        loading-text="Загрузка..."
        :server-items-length="totalCount"
        :footer-props="{
        itemsPerPageOptions: [10, 50, 100, 150],
        disableItemsPerPage: false,
        'items-per-page-text':'Элементов на странице:',
        'items-per-page-all-text':'Все'
        }"
        >
            <template v-slot:top>
                <v-dialog
                v-model="dialogEdit"
                max-width="500px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                        color="primary"
                        dark
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                        >
                        Добавить пользователя
                        </v-btn>
                    </template>

                    <v-card>
                        <v-card-title>
                            <span class="text-h5">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        md="12"
                                        v-if="editedIndex === -1"
                                    >
                                        <v-text-field
                                        v-model="editedItem.login"
                                        type="text"
                                        label="Логин пользователя"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        md="12"
                                    >
                                        <v-text-field
                                        v-model="editedItem.email"
                                        type="text"
                                        label="E-mail пользователя"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        md="12"
                                    >
                                        <v-text-field
                                        v-model="editedItem.surname"
                                        type="text"
                                        label="Фамилия пользователя"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        md="12"
                                    >
                                        <v-text-field
                                        v-model="editedItem.firstname"
                                        type="text"
                                        label="Имя пользователя"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        md="12"
                                    >
                                        <v-text-field
                                        v-model="editedItem.patronymic"
                                        type="text"
                                        label="Отчество пользователя"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        md="12"
                                    >
                                        <v-text-field
                                        v-model="editedItem.phone"
                                        type="text"
                                        label="Номер телефона"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        md="12"
                                    >
                                        <v-select
                                            v-model="editedItem.cities"
                                            :items="cities"
                                            :menu-props="{ maxHeight: '400' }"
                                            label="Город"
                                            multiple
                                            hint="Города, с которыми может работать пользователь"
                                            persistent-hint
                                            item-text="name"
                                            item-value="id"
                                        ></v-select>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        md="12"
                                        v-if="editedIndex === -1"
                                    >
                                        <v-select
                                            v-model="editedItem.roleId"
                                            :items="roles"
                                            :menu-props="{ maxHeight: '400' }"
                                            label="Роль"
                                            persistent-hint
                                            item-text="name"
                                            item-value="id"
                                        ></v-select>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        md="6"
                                        v-if="editedIndex === -1"
                                    >
                                        <v-text-field
                                        v-model="editedItem.password"
                                        type="password"
                                        label="Новый пароль"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        md="6"
                                        v-if="editedIndex === -1"
                                    >
                                        <v-text-field
                                        v-model="editedItem.confirm_password"
                                        type="password"
                                        label="Подтверждение пароля"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        md="12"
                                    >
                                        <v-checkbox
                                            v-model="editedItem.active"
                                            label="Аккаунт активирован"
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        md="12"
                                    >
                                        <v-checkbox
                                            v-model="editedItem.banned"
                                            label="Заблокировать аккаунт"
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="closeEdit"
                            >
                                Отмена
                            </v-btn>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="save"
                            >
                                Сохранить
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog
                v-model="dialogChangePassword"
                max-width="500px"
                >
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">Изменение пароля</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        md="6"
                                        v-if="editedIndex > -1"
                                    >
                                        <v-text-field
                                        v-model="editedItem.password"
                                        type="password"
                                        label="Новый пароль"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        md="6"
                                        v-if="editedIndex > -1"
                                    >
                                        <v-text-field
                                        v-model="editedItem.confirm_password"
                                        type="password"
                                        label="Подтверждение пароля"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="closePasswordEdit"
                            >
                                Отмена
                            </v-btn>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="savePassword"
                            >
                                Сохранить
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>

            <template #[`item.active`]="{ item }">
                <v-simple-checkbox
                v-model="item.active"
                disabled
                ></v-simple-checkbox>
            </template>

            <template #[`item.banned`]="{ item }">
                <v-simple-checkbox
                v-model="item.banned"
                disabled
                ></v-simple-checkbox>
            </template>

            <template #[`item.actions`]="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                    title="Изменить данные"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    small
                    class="mr-2"
                    @click="editPassword(item)"
                    title="Изменить пароль"
                >
                    mdi-form-textbox-lock
                </v-icon>
            </template>
        </v-data-table>
    </div>
</template>

<script>
export default {
    name: "Users",
    data() {
        return {
            list: [],
            cities: [],
            roles: [],
            types: [],
            roleId: 2,
            headers: [
                {
                    value: "id",
                    text: "ID",
                    sortable: false,
                },
                {
                    value: "login",
                    text: "Логин",
                    sortable: false,
                },
                {
                    value: "name_string",
                    text: "ФИО",
                    sortable: false
                },
                {
                    value: "city_string",
                    text: "Города",
                    sortable: false,
                },
                {
                    value: "active",
                    text: "Аккаунт активирован",
                    sortable: false,
                },
                {
                    value: "banned",
                    text: "Аккаунт заблокирован",
                    sortable: false,
                },
                {
                    value: 'actions',
                    text: 'Редактирование',
                    sortable: false,
                    align: 'end',
                },
            ],
            loading: false,
            pg: 1,
            page: 1,
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: ['id']
            },
            totalCount: -1,
            hasNext: false,
            dialogEdit: false,
            dialogChangePassword: false,
            editedIndex: -1,
            editedItem: {
                id: 0,
                login: '',
                firstname: '',
                patronymic: '',
                surname: '',
                phone: '',
                cities: [],
                roleId: 0,
                email: '',
                active: true,
                banned: false
            },
            defaultItem: {}
        };
    },
    watch: {
        options(pagination) {
            this.page = pagination.page;
            this.getList(pagination.page);
        },
        dialogEdit (val) {
            val || this.closeEdit();
        },
        dialogChangePassword (val) {
            val || this.closePasswordEdit();
        },
    },
    mounted() {
        this.getCities();
        this.getRoles();
        this.getTypes();
    },
    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'Новый пользователь' : 'Редактирование пользователя #' + this.editedItem.id;
        }
    },
    methods: {
        async getList(pg = 1) {
            this.loading = true;

            try {
                const { list, hasNext, totalCount } = await this.$api(`/admin/users?${new URLSearchParams({
                    pg: pg,
                    on: this.options.itemsPerPage,
                    roleId: this.roleId
                })}`);

                list.forEach((user, index) => {
                    let city_names = [];

                    user.cities.forEach((city) => {
                        city_names.push(city.name);
                    });

                    list[index].city_string = city_names.join(', ');

                    list[index].name_string = list[index].surname + " " + list[index].firstname + " " + list[index].patronymic;
                });

                this.list = list;
                this.hasNext = hasNext;
                this.pg = pg;
                this.totalCount = totalCount;
            } catch (e) {
                this.$error(e.message);
            }

            this.loading = false;
        },
        async getCities() {
            try {
                const { list } = await this.$api("/cities");

                this.cities = list;
            } catch (e) {
                this.$error(e.message);
            }
        },
        async getTypes() {
            try {
                const { list } = await this.$api("/admin/roles/types");

                this.types = list;
            } catch (e) {
                this.$error(e.message);
            }
        },
        async getRoles() {
            try {
                const { list } = await this.$api("/admin/roles");

                this.roles = list;
            } catch (e) {
                this.$error(e.message);
            }
        },
        async editItem (item) {
            try {
                let r = await this.$api(`/admin/users/${item.id}`);

                this.editedIndex = this.list.indexOf(item);
                this.defaultItem = Object.assign({}, this.editedItem);
                this.editedItem = Object.assign({}, r);
                this.dialogEdit = true;
            } catch (e) {
                this.$error(e.message);
            }
        },
        closeEdit () {
            this.dialogEdit = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        async save () {
            if (this.editedIndex > -1) {
                try {
                    let r = await this.$api("/admin/users/" + this.editedItem.id, {
                        method: "PUT",
                        body: {
                            city_ids: this.editedItem.cities,
                            phone: this.editedItem.phone,
                            firstname: this.editedItem.firstname,
                            patronymic: this.editedItem.patronymic,
                            surname: this.editedItem.surname,
                            email: this.editedItem.email,
                            active: this.editedItem.active,
                            banned: this.editedItem.banned
                        },
                    });

                    if (!r.error) {
                        this.getList();
                        this.closeEdit();
                    }
                } catch (e) {
                    this.$error(e.message);
                }
            } else {
                try {
                    let r = await this.$api("/admin/users", {
                        method: "POST",
                        body: {
                            login: this.editedItem.login,
                            email: this.editedItem.email,
                            firstname: this.editedItem.firstname,
                            patronymic: this.editedItem.patronymic,
                            surname: this.editedItem.surname,
                            phone: this.editedItem.phone,
                            password: this.editedItem.password,
                            confirm_password: this.editedItem.confirm_password,
                            city_ids: this.editedItem.cities,
                            role_id: this.editedItem.roleId,
                            active: this.editedItem.active,
                            banned: this.editedItem.banned
                        },
                    });
                    if (!r.error) {
                        this.getList();
                        this.closeEdit();
                    }
                } catch (e) {
                    this.$error(e.message);
                }
            }
        },
        editPassword (item) {
            this.editedIndex = this.list.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogChangePassword = true;
        },
        closePasswordEdit() {
            this.dialogChangePassword = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        async savePassword () {
            if (this.editedIndex > -1) {
                try {
                    let r = await this.$api("/users/" + this.editedItem.id, {
                        method: "PATCH",
                        body: {
                            password: this.editedItem.password,
                            confirm_password: this.editedItem.confirm_password,
                        },
                    });
                    if (!r.error) this.closePasswordEdit();
                } catch (e) {
                    this.$error(e.message);
                }
            }
        }
    }
};
</script>
