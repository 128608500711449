<template>
    <v-app>
        <template v-if="!isLogin">
            <v-overlay :value="loading" z-index="1000">
                <v-progress-circular indeterminate size="64" />
            </v-overlay>
            <v-navigation-drawer
                app
                :width="360"
                :temporary="!isHome"
                :permanent="isHome"
                v-model="drawer"
            >
                <v-list>
                    <v-list-item>
                        <v-list-item-title>Панель управления</v-list-item-title>
                    </v-list-item>
                </v-list>
                <v-divider />
                <v-list>
                    <v-list-item
                        :to="{ name: 'Users' }"
                        v-if="$store.state.auth.admin"
                    >
                        <v-list-item-icon>
                            <v-icon>mdi-account-multiple</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Пользователи</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'News' }">
                        <v-list-item-icon>
                            <v-icon>mdi-newspaper-variant</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Новости</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'Comments' }">
                        <v-list-item-icon>
                            <v-icon>mdi-comment-multiple</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title
                            >Комментарии к новостям</v-list-item-title
                        >
                    </v-list-item>
                    <v-list-item :to="{ name: 'Tags' }">
                        <v-list-item-icon>
                            <v-icon>mdi-tag-multiple</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title
                            >Категории новостей</v-list-item-title
                        >
                    </v-list-item>
                    <v-list-item :to="{ name: 'Banners' }">
                        <v-list-item-icon>
                            <v-icon>mdi-account-cash</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Баннеры</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'Ads' }">
                        <v-list-item-icon>
                            <v-icon>mdi-cash-100</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Объявления</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'Categories' }">
                        <v-list-item-icon>
                            <v-icon>mdi-cash-100</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title
                            >Категории объявлений</v-list-item-title
                        >
                    </v-list-item>
                    <v-list-item :to="{ name: 'Orders' }">
                        <v-list-item-icon>
                            <v-icon>mdi-cart</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Заказы объявлений</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'OrganizationCardCategories' }">
                        <v-list-item-icon>
                            <v-icon>mdi-tag-multiple</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title
                            >Категории карточки организации</v-list-item-title
                        >
                    </v-list-item>
                    <v-list-item :to="{ name: 'OrganizationCards' }">
                        <v-list-item-icon>
                            <v-icon>mdi-cards</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title
                            >Карточки организации</v-list-item-title
                        >
                    </v-list-item>
                </v-list>
                <v-divider />
                <v-list>
                    <v-list-item @click="downloadUsersExcel">
                        <v-list-item-icon>
                            <v-icon>mdi-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Выгрузить список пользователей</v-list-item-title>
                    </v-list-item>
                </v-list>
                <v-divider />
                <v-list>
                    <v-list-item @click="logout">
                        <v-list-item-icon>
                            <v-icon>mdi-logout</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Выход</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-navigation-drawer>
            <v-app-bar app>
                <v-app-bar-nav-icon
                    @click.stop="drawer = !drawer"
                    v-if="!isHome"
                />
                <v-toolbar-title>{{ $route.meta.title }}</v-toolbar-title>
            </v-app-bar>
        </template>

        <v-main>
            <v-container :fill-height="isLogin" fluid>
                <router-view />
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import { saveAs } from 'file-saver';

export default {
    name: "App",

    data: () => ({
        API_URL: process.env.VUE_APP_API,
        drawer: false,
        loading: false,
    }),

    computed: {
        isLogin() {
            return this.$route.name == "Login";
        },
        isHome() {
            return this.$route.name == "Home";
        },
    },
    methods: {
        async downloadUsersExcel () {
            try {
                let r = await this.$api("/admin/users/excel");
                if (!r.error) {
                    await saveAs(this.API_URL + r.file, "users.xlsx");
                    await this.$api("/admin/users/remove-excel", {
                        method: 'DELETE'
                    });
                }
            } catch (e) {
                this.$error(e.message);
            }
        },
        async logout() {
            this.loading = true;
            await this.$api("/logout");
            this.$store.commit("auth/token", null);
            this.$router.push({ name: "Login" });
            this.drawer = false;
            this.loading = false;
        },
    },
};
</script>
